<style scoped>
.demand-table-row {
  background-color: #141414;
  padding: 5px 10px;
  cursor: pointer;
}
.demand-table-row:hover {
  background-color: #444;
}
.demand-table-row-active {
  background-color: #2d8cf0;
  padding: 5px;
  cursor: pointer;
}
</style>

<template>
  <div>
    <Divider dashed><span class="divider-text">广告投放汇总</span></Divider>
    <Table
      stripe
      size="small"
      :data="adList"
      :columns="dataColumns"
    ></Table>

    <!-- <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
      ></Page>
    </div> -->
  </div>
</template>

<script>
// import { GetDateStr } from '@/utils/dateFormat'

import { getProgramInfo } from '@/api/inventory/editing'

export default {
  data () {
    return ({
      query: {
        keyword: '',
        pageNumber: 1,
        pageSize: 15
      },
      adList: [],
      dataColumns: [
        {
          title: '客户（品牌）',
          width: 160,
          key: 'advertiserName',
          render: (h, params) => {
            return h('span', `${params.row.advertiserName}(${params.row.brandName})`)
          }
        },
        { title: '规格（秒）', width: 90, align: 'center', key: 'duration' },
        { title: '次数', align: 'right', key: 'playNumber' }
      ],
      total: 0,
      curAdvertiserId: null,
      curBrandId: null
    })
  },
  computed: {
    curTaskInfo () {
      return this.$store.state.electronic.curTaskInfo
    }
  },
  // created () {
  //   this.initPageData()
  // },
  methods: {
    initPageData () {
      getProgramInfo({ programId: this.curTaskInfo.id }).then(res => {
        if (res && !res.errcode) {
          this.adList = res
        } else {
          this.adList = []
        }
      })
    },
    handelSearchAd () {

    },
    handleClickAd (item) {
      if (this.curAdvertiserId === item.advertiserId && this.curBrandId === item.brandId) {
        this.curAdvertiserId = null
        this.curBrandId = null
      } else {
        this.curAdvertiserId = item.advertiserId
        this.curBrandId = item.brandId
      }
    }
  },
  watch: {
    curTaskInfo: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.id) {
          this.initPageData()
        }
      }
    }
  }
}
</script>
